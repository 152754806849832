<template>
  <div>
    <el-container>
      <el-header><p style="color: #409EFF; cursor:pointer;text-align: center">{{ content }}</p></el-header>
      <el-progress :text-inside="true" :stroke-width="26" :percentage="percentage"
                   style="margin-bottom: 20px" :color="customColors"></el-progress>
      <el-main>
        <el-form :model="form" ref="form" class="demo-ruleForm">
          <el-form-item label="M3U8地址" prop="m3u8Url">
            <el-input placeholder="请输入M3U8地址" v-model="form.m3u8Url">
              <template slot="prepend">https://</template>
            </el-input>
          </el-form-item>
          <el-form-item label="文件名称" prop="fileName">
            <el-input v-model="form.fileName" placeholder="请输入保存文件名称"></el-input>
          </el-form-item>
          <el-form-item label="线程数量" class="text-right">
            <el-input-number v-model="form.threadCount" :min="1" :max="100"
                             label="线程数量"></el-input-number>
          </el-form-item>
          <el-form-item label="重试次数" class="text-right">
            <el-input-number v-model="form.retryCount" :min="1" :max="5"
                             label="重试次数"></el-input-number>
          </el-form-item>
          <!--                    <el-form-item label="超时时间(秒)" class="text-right">-->
          <!--                        <el-input-number v-model="form.timeout" :min="5" :max="30" label="超时时间"></el-input-number>-->
          <!--                    </el-form-item>-->
          <el-form-item label="反馈类型" class="text-right">
            <el-select v-model="form.resultWay" placeholder="请选择">
              <el-option label="邮箱" value="1"></el-option>
              <el-option label="接口" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="反馈地址" class="text-right">
            <el-input v-model="form.urlOrEmail" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-form>
      </el-main>
      <el-footer>
        <p style="text-align: center">
          <el-button type="primary" @click="fetchData">提交</el-button>
          <el-button type="primary" @click="dialogVisible = true">下载记录</el-button>

        </p>
      </el-footer>
    </el-container>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <el-table
          :data="tableData"
          style="width: 100%"
          max-height="250">
        <el-table-column
            fixed
            prop="date"
            label="日期"
            width="150">
        </el-table-column>
        <el-table-column
            prop="name"
            label="姓名"
            width="120">
        </el-table-column>
        <el-table-column
            prop="province"
            label="省份"
            width="120">
        </el-table-column>
        <el-table-column
            prop="city"
            label="市区"
            width="120">
        </el-table-column>
        <el-table-column
            prop="address"
            label="地址"
            width="300">
        </el-table-column>
        <el-table-column
            prop="zip"
            label="邮编"
            width="120">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="120">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="deleteRow(scope.$index, tableData)"
                type="text"
                size="small">
              移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>


      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "external",
  data() {
    return {
      form: {
        m3u8Url: '',
        fileName: '',
        threadCount: 10,
        retryCount: 1,
        timeout: 10,
        resultWay: "1",
        urlOrEmail: '',
        accessHost: '',
      },
      customColors: [
        {color: '#F56C6C', percentage: 20},
        {color: '#e6a23c', percentage: 40},
        {color: '#5cb87a', percentage: 60},
        {color: '#1989fa', percentage: 80},
        {color: '#6f7ad3', percentage: 100}
      ],
      percentage: 0,
      timer: null,
      content: '等待下载',
      mp4Path: '/',
      dialogVisible: false,
      tableData: [{
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }]
    }
  },
  mounted() {

  },
  methods: {
    fetchData() {
      this.form.accessHost = window.location.origin;
      let this_ = this;
      fetch('/file/download', {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
          "Accept": "text/event-stream",
        },
        body: JSON.stringify(this.form) // 如果有请求体，请将其作为字符串传递
      })
          .then(response => {
            const reader = response.body.getReader();
            let buffer = '';

            function processStream({done, value}) {
              if (done) {
                console.log('连接已关闭');
                return;
              }
              const chunk = new TextDecoder('utf-8').decode(value);
              buffer += chunk;
              // 处理分隔的消息
              while (buffer.includes('\n\n')) {
                const separatorIndex = buffer.indexOf('\n\n');
                const message = buffer.substring(0, separatorIndex);
                buffer = buffer.substring(separatorIndex + 2);
                // 处理消息数据，去掉"data:"前缀
                const data = message.replaceAll("\n", "").replaceAll("\t", "").replaceAll("data:", "")
                let obj = JSON.parse(data)
                this_.content = obj['rate']
                this_.percentage = Number(obj['plan'])
              }
              // 继续读取下一个结果
              return reader.read().then(processStream);
            }

            // 读取第一个结果
            return reader.read().then(processStream);
          })
          .catch(error => {
            console.error('请求错误:', error);
          });

    }
  }
}
</script>

<style scoped>

</style>